@import url('https://fonts.googleapis.com/css2?family=Anuphan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap');

:root {
  --background-light: #efefef;
  --text-light: black;
  --background-dark: #212529;
  --text-dark: white;
  --background-accent: #65655e;
}

.light-mode {
  --background: var(--background-light);
  --text-color: var(--text-light);
}

.dark-mode {
  --background: var(--background-dark);
  --text-color: var(--text-dark);
}

.dark-mode-accent {
  background-color: #2C3639 !important;
}

html {
  scroll-behavior: smooth;
}

.App {
  font-family: 'Anuphan', sans-serif;
  padding-top: 0;
  background-color: var(--background);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.no-resize {
  resize: none;
}

.pf {
  font-family: 'Playfair Display', 'serif';
  letter-spacing: 1px;
}

.fr {
  font-family: 'Frank Ruhl Libre', serif;
  letter-spacing: 1px;
}

.form-field {
  height: 2rem;
}

.form-control {
  border-radius: 0 !important;
}

.nb-card-footer {
  border-top: none !important;
}

.bs-card {
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

.top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  box-shadow: none;
  opacity: 0.6;
}

.top-button:hover {
  opacity: 1;
}

.external-btn {
  width: 125px;
}

.square-btn {
  width: 125px;
  height: 125px;
}

.accordion {
  --bs-accordion-active-color: white !important;
  --bs-accordion-active-bg: #198754 !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.modal-content {
  border-radius: 0 !important;
}

.definition {
  font-weight: bold;
  color: #198754;
}

.definition:hover {
  color: #198754;
  cursor: pointer;
}

.pagination .page-item.active .page-link {
  background-color: #198754 !important;
  color: white !important;
}

.pagination .page-link:hover {
  background-color: #198754 !important;
  color: white !important;
}

/* Video Backgrounds */

.video-background {
  position: relative;
  height: 75vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(0,0,0,0.5);
}

.video-overlay-header {
  font-family: 'Merriweather', 'serif';
  letter-spacing: 1px;
  text-shadow: 0px 5px 12px black;
}

.video-overlay-body {
  font-size: 1rem;
  width: 70%;
  text-shadow: 0px 5px 12px black;
}

.video-background-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Header */

.header {
  position: absolute;
  top: 0;
  left: 0;
  min-width: calc(100vw - (100vw - 100%));
  --bs-navbar-color: white !important;
}

.header-logo-full {
  height: 18vh;
}

.header-logo-bare {
  height: 10vh;
  position: relative;
  z-index: 102;
}

.navbar .navbar-toggler {
  z-index: 102;
}

.header .container-fluid {
  padding: 0;
}

.header .navbar-toggler {
  color: white !important;
  --bs-navbar-toggler-border-color: rgba(0,0,0,0);
  --bs-navbar-toggler-focus-width: 0rem;
  margin-right: 0.5rem;
}

.header .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27white%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.header .nav-link-main.active {
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

.header .nav-link-main:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

.header .nav-link-main {
  letter-spacing: 0.5px;
}

.header-row {
  min-height: 24vh;
}

@media (max-width: 767.98px) {
  .header-row {
    min-height: 16vh;
  }
}

@media (max-width: 991.98px) {
  .navbar .navbar-collapse{
    background: rgba(0,0,0,0.95);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center !important;
    height: 100vh;
  }

  .navbar .navbar-collapse.collapse{
    display: flex;
    opacity: 0;
    pointer-events: none;
  }

  .navbar .navbar-collapse.collapse.show{
    opacity: 1;
    pointer-events: auto;
  }

  .navbar .navbar-collapse .navbar-nav{
    margin-right: initial !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  .navbar-nav {
    margin-left: initial !important;
  }
}

.header-menu {
  z-index: 101;
}

.display-mode-btn {
  color: white;
  cursor: pointer;
  margin: auto 0px auto 8px;
}

@media (max-width: 991.98px) {
  .display-mode-btn {
    margin: 7px auto 4px auto;
  }
}

/* Footer */

.footer {
  margin-top: auto;
}

.footer .navbar .nav-link.active {
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

.footer .navbar .nav-link:hover {
  color: black;
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

/* Home Page */

.home-page-section {
  margin-bottom: 10rem;
}

.home-help-panel-header {
  position: absolute;
  height: 300px;
  z-index: 0;
}

.home-help-panel-content {
  z-index: 1;
  margin-top: 175px !important;
  width: 60% !important;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

@media (max-width: 475.98px) {
  .home-help-panel-content {
    margin-top: 195px !important;
  }
}

@media (max-width: 1400.98px) {
  .home-help-panel-content {
    width: 80% !important;
  }
}

/* News & Articles */

.article-card {
  text-align: center;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  min-height: 550px;
}

.article-card-info {
  position: absolute;
  bottom: 5px;
  width: 90%;
}

.article-card-bookmark {
  cursor: pointer;
}

.article-title {
  max-height: 4.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.article-preview-btn {
  width: 100px !important;
}

.article-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.featured-article {
  border: 1px solid rgba(0, 0, 0, 0.175);
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

.featured-article-title {
  min-height: 1.25em;
  max-height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.featured-article-snippet {
  height: 9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

@media (max-width: 1399.98px) {
  .featured-article-content {
    height: 362px;
  }
}

@media (max-width: 1399.98px) and (min-width: 1200.98px) {
  .featured-article-snippet {
    height: 7.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.featured-article-external-btn {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0px;
  left: 0px;
}

.toggle-read-more {
  cursor: pointer;
}

.toggle-read-more:hover {
  color: #198754;
}

.pagination .page-link {
  color: black !important;
  background-color: white;
  border-color: black;
  --bs-pagination-focus-box-shadow: none !important;
  min-width: 35px;
}

.pagination .page-link:hover {
  color: black;
  background-color: white;
  border-color: black;
  background: rgba(0,0,0,0.1);
}

.pagination .page-item.active .page-link {
  color: white !important;
  background-color: black;
  border-color: black;
}

/* News Filter */

.news-search {
  position: relative;
  padding-right: 0 !important;
}

.searchbar {
  padding-right: 40px;
}

.searchbar-icons {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer
}

/* Learn Section */

.learn-page {
  overflow-x: hidden;
}

.learn-section {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (min-width: 767.98px) {
  .learn-img-container {
    height: 500px;
  }
}

.learn-img {
  max-height: 75%;
  overflow: hidden;
}

.learn-importance-vid {
  width: 75%;
}

@media (max-width: 767.98px) {
  .learn-importance-vid {
    width: 100%;
  }
}

.scene-tooltip {
  background: black;
  padding: 6px;
  font-weight: bold;
}

.globe-data-card {
  position: absolute !important;
  top: 12px;
  right: 12px;
  width: 350px;
}

@media (max-width: 575.98px) {
  .globe-data-card {
    right: 24px;
  }
}

.globe-data-card .card-body {
  max-height: 340px;
  overflow-y: scroll;
}

.icon-wrapper {
  background: rgba(0,0,0,0.4);
  border-radius: 50%;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-wrapper:hover {
  background: rgba(0,0,0,0.6);
}

.podcast-card {
  max-height: 250px;
  max-width: 250px;
}

.podcast-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
}

.podcast-card-overlay:hover {
  background: rgba(0, 0, 0, 0.8);
}

.podcast-card-btn {
  width: 25%;
}

/* Stories Section */

.story-card-img {
  max-height: 200px;
  object-fit: cover;
}

.story-card-btn {
  min-width: 40px;
  width: 15%;
}

.story-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.story-main-content {
  margin: 25vh 0 50vh 0; 
}

.story-media-content {
  position: sticky;
  top: 0; 
  height: 100vh;
  margin: 25vh 0 50vh 0; 
}

.story-media-img {
  max-height: 420px;
}

.story-video-wrapper {
  width: 560px;
  height: 315px;
}

.story-step {
    margin: 100vh 0;
}

.blockquote {
  font-size: 1rem !important;
}

.blockquote-footer {
  color: white !important;
}

.chart-tooltip {
  background-color: #fff;
}

.story-mobile-row {
  padding-top: 200px;
  padding-bottom: 200px;
}

.recharts-responsive-container {
  width: 100% !important;
  height: 50vh !important;
}

.recharts-legend-wrapper {
  display: flex !important;
  justify-content: center;
}

.spotlight {
  color: #E4C239;
}

.story-nav {
  background: #efefef;
  border-bottom: 1px solid #212529;
}

.story-nav-link {
  text-decoration: none;
  color: #212529;
  font-weight: bold;
}

.story-nav-link:hover {
  color: #212529;
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

.story-author-img {
  height: 100px;
}

.story-author-img-sm {
  height: 60px;
  border: 1px solid rgba(0,0,0,0.1);
}

.sticky-top {
  z-index: 100 !important;
}

/* Resources Section */

.resource-sidebar-collapse-btn {
  width: 50px;
  box-shadow: none;
}

.resource-card {
  min-height: 300px;
  max-width: 300px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

.resource-card-button {
  box-shadow: none;
}

.resource-category-nav .container-fluid {
  padding-left: 0;
}

.resource-category-nav .nav-item {
  cursor: pointer;
}

.resource-category-nav .nav-item.active {
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

.resource-category-nav .nav-item:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.3em;
  text-decoration-color: #198754;
}

/* Donations Section */

.donations-img {
  width: 300px;
}

.charity-nav-img {
  height: 20px;
}

@media (min-width: 768.98px) {
  .featured-organization-desc {
    width: 75%;
  }
}

.signature {
  height: 50px;
}

@media (min-width: 991.98px) {
  .donations-intro-content {
    width: 75% !important;
  }
}

@media (max-width: 1199.98px) {
  .donations-direct-content {
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 991.98px) {
  .donations-direct-content {
    width: 100% !important;
  }
}

/* Glossary Page */

@media (max-width: 767.98px) { /* Adjust this breakpoint as needed */
  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination-item {
    margin-bottom: 0.6rem;
  }
}

/* Admin Page */

.user-card {
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}

/* Embedded Video */

.responsive-video {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

@media (max-width: 991.98px) {
  .responsive-video iframe {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.responsive-video iframe {
  left: 0;
  top: 0;
  height: 100%;
  max-height: 315px;
  width: 100%;
  max-width: 560px;
  position: absolute;
}

/* Error Handling */

.toast-notification {
  position: fixed;
  top: 80px;
  right: -300px;
  width: 300px;
  padding: 12px;
  background-color: #333;
  color: #fff;
  z-index: 1000;
  transition: right 0.5s ease-in-out;
}

.toast-content {
  display: flex;
  justify-content: space-between;
}

.toast-notification.error {
  background-color: #d9534f;
}

.toast-notification.success {
  background-color: #5bc0de;
}

.toast-notification.visible {
  right: 0;
}

.close-icon {
  cursor: pointer;
  margin-left: 10px;
}